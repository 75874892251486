export type Role =
  | 'SUPERADMIN'
  | 'ADMIN'
  | 'MAINTAINER'
  | 'READER'
  | 'GATEWAY_ADMIN'
  | 'GATEWAY_MAINTAINER'
  | 'GATEWAY_READER'
  | 'TRACKER_ADMIN'
  | 'TRACKER_MAINTAINER'
  | 'TRACKER_READER'
  | 'SITE_ADMIN'
  | 'SITE_MAINTAINER'
  | 'SITE_READER'
  | 'USER_ADMIN'
  | 'USER_MAINTAINER'
  | 'USER_READER'
  | 'SALE_ADMIN'
  | 'SALE_MAINTAINER'
  | 'SALE_READER';

export const GATEWAY_ROLES: Role[] = ['GATEWAY_ADMIN', 'GATEWAY_MAINTAINER', 'GATEWAY_READER'];

export const DEVICE_ROLES: Role[] = ['TRACKER_ADMIN', 'TRACKER_MAINTAINER', 'TRACKER_READER'];

export const SITE_ROLES: Role[] = ['SITE_ADMIN', 'SITE_MAINTAINER', 'SITE_READER'];

export const USER_ROLES: Role[] = ['USER_ADMIN', 'USER_MAINTAINER', 'USER_READER'];

export const SALE_ROLES: Role[] = ['SALE_ADMIN', 'SALE_MAINTAINER', 'SALE_READER'];
