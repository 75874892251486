import { MenuItem } from '@wheere-front/shared-util';
import { DEVICE_ROLES, GATEWAY_ROLES, Role, SALE_ROLES, SITE_ROLES, USER_ROLES } from './role.model';

export type Domain = 'devices' | 'gateways' | 'sales' | 'sites' | 'trackers' | 'users';

export const domainList: Domain[] = ['devices', 'gateways', 'sales', 'sites', 'users'];

export const domainColors = {
  devices: '#F7067D',
  gateways: '#FFC800',
  sales: '#107DED',
  sites: '#5000CD',
  users: '#23D2CD',
};

export const domainBadgeColorClass: Record<Domain, string> = {
  devices: 'bg-primary-50 text-primary-500',
  trackers: 'bg-primary-50 text-primary-500',
  gateways: 'bg-yellow-50 text-yellow-400',
  sales: 'bg-pacificBlue-50 text-pacificBlue-500',
  sites: 'bg-eletricPurple-50 text-eletricPurple-500',
  users: 'bg-aquamarine-50 text-aquamarine-500',
};

export type MenuItemRoles = MenuItem & { roles: Role[] };

export const domainMenuItemsShortcut: MenuItemRoles[] = [
  {
    label: 'home',
    path: '/home',
    roles: [],
  },
  {
    label: 'devices',
    path: '/devices',
    icon: 'module',
    color: domainColors.devices,
    roles: DEVICE_ROLES,
  },
  {
    label: 'gateways',
    path: '/gateways',
    icon: 'gateway',
    color: domainColors.gateways,
    roles: GATEWAY_ROLES,
  },
  {
    label: 'sites',
    path: '/sites',
    icon: 'site',
    color: domainColors.sites,
    roles: SITE_ROLES,
  },
  {
    label: 'users',
    path: '/users',
    icon: 'user',
    color: domainColors.users,
    roles: USER_ROLES,
  },
  {
    label: 'sales',
    path: '/sales',
    icon: 'sale',
    color: domainColors.sales,
    roles: SALE_ROLES,
  },
];
